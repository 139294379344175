/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import 'variables.scss';

.p-menu {
    @apply bg-transparent;
    .p-menu-submenu-label {
        @apply p-0;
        @apply pb-2.5;
        @apply text-primary;
    }
}

.mat-mdc-button-disabled {
    border: 1px solid #475569!important;
}

mat-tab-group {
    .mat-mdc-tab-header {
        @apply rounded-3xl bg-gradient-to-r to-[#E1E9F2] from-[#F6F7F9];

        &:not(.mat-background-primary):not(.mat-background-accent) {
            .mat-mdc-tab-label-container {
                @apply shadow-none;
            }
        }

        .mat-mdc-tab-label-container {
            margin: 0!important;

            .mat-mdc-tab {
                @apply flex-grow text-lg;

                .mdc-tab__content {
                    @apply static;
                }

                .mat-ripple {
                    @apply opacity-0;
                }

                .mdc-tab__ripple {
                    @apply opacity-0;
                }

                &.mdc-tab--active {
                    @apply rounded-3xl bg-card;

                    .mdc-tab__text-label {
                        @apply text-lg font-semibold;
                    }

                    .mdc-tab-indicator__content {
                        @apply opacity-0;
                    }
                }
            }
        }
    }

    .mat-mdc-tab-body-content {
        @apply p-0;
    }
}

.mat-accordion {
    .mat-expansion-panel {
        &:not(.mat-expanded) {
            border-radius: 9999px!important;
        }
    }
}
.mat-mdc-tab-group {
    .mat-mdc-tab-body-content {
        padding: 0px;
    }
}

.dark {
    .p-column-filter-menu-button-active {
        @apply bg-slate-800;
    }
    .p-datatable-column-sorted {
        @apply bg-slate-800;
    }
    .mat-mdc-radio-checked {
        .mdc-radio__inner-circle {
            background-color: #fff !important;
            border-color: #fff !important;
        }
        .mdc-radio__outer-circle {
            border-color: #fff !important;
        }
    }
    .mat-mdc-tab-labels {
        @apply bg-gradient-to-tr;
        @apply dark:from-[#435267];
        @apply dark:to-[#1E293B];
    }
}

.mat-button-toggle {
    .mat-button-toggle-label-content {
        .mat-button-toggle-checkbox-wrapper {
            padding-left: 0;
        }
    }
}

.content-container{
    > router-outlet + * {
        height: 100% !important;
    }
}
.p-datatable-header th{
    background-color: transparent !important;
}

.dark .p-datatable-header th{
    color: theme('colors.on-primary') !important;
}
.ng2-pdf-viewer-container .page{
    margin: 0 !important;
    border-width: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
    box-sizing: border-box;
    /* 1 */
    border-width: 0;
    /* 2 */
    border-style: solid;
    /* 2 */
    border-color: theme('borderColor.DEFAULT', 'currentColor');
    /* 2 */
}

::before,
::after {
    --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
    line-height: 1.5;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
    -moz-tab-size: 4;
    /* 3 */
    tab-size: 4;
    /* 3 */
    font-family: theme('fontFamily.sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
    /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
    margin: 0;
    /* 1 */
    line-height: inherit;
    /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
    height: 0;
    /* 1 */
    color: inherit;
    /* 2 */
    border-top-width: 1px;
    /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
    text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
    color: inherit;
    text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
    font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
    font-family: theme('fontFamily.mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
    font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

/*
1. Remove text indentation from flow-data-table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct flow-data-table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between flow-data-table borders by default.
*/

table {
    text-indent: 0;
    /* 1 */
    border-color: inherit;
    /* 2 */
    border-collapse: collapse;
    /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: inherit;
    /* 1 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
    text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

/*button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button; !* 1 *!
    background-color: transparent; !* 2 *!
    background-image: none; !* 2 *!
}*/

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
    outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
    box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
    vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
    -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
    display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
}

legend {
    padding: 0;
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
    resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::placeholder,
textarea::placeholder {
    opacity: 1;
    /* 1 */
    color: theme('colors.gray.400', #9ca3af);
    /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
    cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
    cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    /* 1 */
    vertical-align: middle;
    /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
    max-width: 100%;
    height: auto;
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/

[hidden] {
    display: none;
}

/***********************************************
Custom Styles
 */
.mat-row:nth-child(even) {
    background-color: rgb(241, 245, 249);
}

.mat-row:nth-child(odd) {
    background-color: #ffffff;
}

.invoice-new-sheet,
.view-invoice-sheet {
    width: calc(100vw - 200px);
    height: calc(100vh - 200px);
    padding: 0 !important;
    background: #f1f5f9 !important;
}

.new-product-sheet {
    height: calc(100vh - 200px);
    padding: 0 !important;

}

.custom-width {
    width: calc(100vw - 800px);
    height: calc(100vh - 300px);
    padding: 0 !important;
    background: #f1f5f9 !important;
}

.mat-bottom-sheet-container {
    width: 100%;
    padding: 0 !important;
}

.mat-bottom-sheet-container-large {
    max-width: calc(100vw - 200px) !important;
}


mat-label {
    font-weight: 500;
}

.item-header flow-alert .flow-alert-container {
    padding: 5px 12px !important;
    margin: 0 !important;
}

/*.item-header flow-alert{
    padding: 0 !important;
    margin-left: 20px !important;
}*/

.check-container {
    min-height: calc(100vh - 120px);
    overflow-y: auto;
}

/*.single-edit-tabs .mat-tab-labels{
    flex-direction: row;
}
.single-edit-tabs .mat-tab-labels > div:last-child{

    justify-content: end;

}*/
.rankings-progress .mat-progress-bar-fill::after {
    background-color: #134e4a !important;
}

.rankings-progress .mat-progress-bar-buffer {
    background: #ccfbf1 !important;
}

.invoices-progress .mat-progress-bar-fill::after {
    background-color: #581c87 !important;
}

.invoices-progress .mat-progress-bar-buffer {
    background: #f3e8ff !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border-radius: 9999px !important;
}

.mat-button-toggle-group {
    border: 1px solid #e2e8f0 !important;
}

body.light .mat-button-toggle-checked,
body .light .mat-button-toggle-checked {
    background: #4f46e5;

}

.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content,
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content .mat-icon {
    color: #ffffff !important;
}

.mat-button-toggle-group> :not([hidden])~ :not([hidden]) {
    margin-right: 0;
    margin-left: 0;
}

.mat-button-toggle-group .mat-button-toggle {
    border-radius: unset;
}

.e-btn-icons.e-circle-add:before {
    content: '\e768';
    font-size: 20px;
}

.p-autocomplete .p-button {}

.p-autocomplete .p-element {
    padding-top: 11px;
    padding-bottom: 11px;
}

.p-inputtext:enabled:focus {
    border-color: theme('colors.gray.400', #9ca3af);
}
.p-autocomplete.p-component.p-inputwrapper{
    @apply w-full;
}

.p-autocomplete .p-inputtext {
    line-height: 18px;
    font-size: 14px;
    padding: 11px !important;
    background-color: transparent !important;
    border: 1px solid;
    width: 100% !important;
    @apply dark:border-white border-gray-600;
}

.p-autocomplete .p-autocomplete-dropdown {
    width: 2rem !important;
    background-color: transparent !important;
    border: 1px solid;
    @apply dark:border-white border-gray-600;
}

.p-autocomplete {
    margin-top: 3px;
}

.detail-lines flow-card {
    overflow: visible !important;
}

p-dropdown.ng-invalid>.p-dropdown {
    border-color: #f44336; // This color HEX value comes from PrimeNG.
}


.mat-button.button-small.detail-line-button {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    border-radius: 4px !important;
    padding: 0 8px !important;
}


p-calendar {
    .p-inputtext {
        border: 1px solid #94a3b8;
        --tw-border-opacity: 1 !important;

        @apply py-0 leading-10 bg-white;
        @apply dark:border-gray-600 dark:bg-slate-800;
    }

    .p-element.p-datepicker-trigger.p-button-icon-only {
        height: 42px;
    }
}

.theme-default p-multiselect {
    border: 1px solid;

    @apply border-[#cbd5e1] bg-white shadow-sm dark:border-opacity-100 dark:border-[#64748b] dark:bg-black dark:bg-opacity-5 #{'!important'};

    .p-multiselect-label {
        @apply dark:text-white;
    }

    .p-multiselect-trigger {
        width: 1.2rem !important;
        padding-right: 10px !important;

        .p-icon {
            color: #0f172a;
            width: 10px;
            height: 10px;
        }
    }
}

p-dropdown {
    .p-inputwrapper {
        line-height: 18px;
    }
}

p-table {
    p-paginator {
        .p-paginator-rpp-options {
            &.p-dropdown {
                width: 6rem;
            }
        }
    }
}

.p-datepicker table td {
    padding: 0 !important;
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0 0 !important;
    border-top: 1px solid #dee2e6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    padding: .50rem !important;
}

p-selectbutton {
    >.p-selectbutton {
        border: 1px solid #d4d4d8;
        border-radius: 8px;

        >.p-button {
            &:not(:first-child):not(.p-highlight) {
                border-left: 1px solid #d4d4d8;
            }
        }
    }
}

.p-chips-token-label {
    min-width: 140px;
}

p-splitbutton {
    &.p-button-rounded {
        >.p-splitbutton {
            &.p-component {
                font-size: 14px;

                >.p-splitbutton-defaultbutton {
                    border-radius: 2rem;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                >.p-splitbutton-menubutton {
                    border-radius: 2rem;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}

mat-button-toggle-group {
    mat-button-toggle {
        mat-pseudo-checkbox {
            display: none !important;
        }
    }
}

.ql-mention-list {
    @apply bg-gray-100;
    @apply rounded;
    @apply max-h-48;
    @apply w-100;
    @apply overflow-y-scroll;
    @apply text-secondary;
    list-style-type: none;
    z-index: 10000;
}

.ql-mention-list .ql-mention-list-item {
    cursor: pointer;
    @apply px-1;
    @apply py-2
}

.ql-mention-list .ql-mention-list-item.selected {
    background-color: rgba(0, 0, 0, 0.12);
}

span.mention {
    @apply text-primary;
    @apply font-semibold;

}
.p-paginator-page-selected{
    @apply bg-primary-500 dark:bg-primary-200;
}
.p-datatable-thead{
    background-color: #ffffff !important;
}

thead {
    tr {
        th {
            .p-column-filter-menu-button-active {
                background-color: hsl(240, 60%, 92%);
                path {
                    @apply text-primary;
                }
            }
        }
    }
}

p-paginator {
    .p-paginator {
        padding-left: 0;
        justify-content: flex-start;
    }
}

.p-datatable-header {
    @apply lg:absolute;
    @apply lg:bg-transparent;
    bottom: 2px;
    right: 0;
    p-multiselect {
        .p-multiselect-label-container {
            line-height: 22px;
        }
    }
}
.mdc-evolution-chip__text-label.mat-mdc-chip-action-label{
    @apply w-full;
}

.custom-dialog-padding .mdc-dialog__surface {
    padding: 0px !important;
}

.no-border-field {
    .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        padding: 0 !important;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
}
